/* Theme styles */

:root {
  --font: "Open Sans", sans-serif;
  --font2: "Roboto", sans-serif;
  --green: #51cc47;
  --green_hover: #46b33c;
  --green2: #3a5548;
  --bg: #24252b;
  --bg2: #4e5962;
  --bgsection: #4f5155;
  --header_height: 150px;
  --dark: #171717;
  --arial: "Arial Regular";
  --arial_bold: "Arial Bold";
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: var(--font);
  font-size: 18px;
}
p {
  margin-top: 0;
}
.hidden {
  display: none !important;
}
button {
  cursor: pointer;
}

.container {
  max-width: 1410px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1480px) {
  .container {
    max-width: 1410px;
  }
}

h1 {
  font-size: 68px;
  line-height: 112%;
  font-weight: bold;
  margin-top: 0;
}
h2 {
  color: #fff;
  font-family: var(--font);
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 112.069%;
  margin-top: 0;
}
h3 {
  color: #fff;
  font-family: var(--font);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.667%;
  margin-top: 0;
}
h4 {
  color: #d2d2d2;
  font-family: var(--font);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 260%;
  margin-top: 0;
}

.button {
  display: inline-flex;
  padding: 12px 40px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: normal;
  font-family: var(--font2);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #fff;
  border-radius: 32px;
  background: var(--green);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.button:hover{
  background: var(--green_hover);
  transition: background 0.2s linear forwards;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #858585;
}

header {
  background-color: var(--bg);
  width: 100%;
  z-index: 1000;
  position: fixed;
}
.header_wrapper {
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
nav.navigation {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}
.product_slider {
  width: 365px;
}
.title_slider,
.title_slider .slick-list {
  height: 60px;
  width: 450px;
}
.logo {
  margin-right: auto;
}
.action {
  margin-left: auto;
}
ul.main_nav {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  align-items: center;
  list-style-type: none;
}
.nav-link {
  color: #d2d2d2;
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
}
.nav-link:hover {
  color: #fff;
}
a.btn.btn-action {
  color: var(--green);
  text-decoration: none;
  border: solid 2px var(--green);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.full_height {
  height: 100vh;
}
.header_section {
  background-image: url("./assets/images/bg_smart-1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.col-50 {
  width: 50%;
}
.col-third {
  width: 33.3%;
}
.header_height_correct {
  margin-top: calc(var(--header_height) / 2);
}
.section {
  padding: 80px 0;
  position: relative;
}
.bg2 {
  background-color: var(--bg);
  color: #fff;
}
.bg3 {
  background-color: var(--dark);
  color: #fff;
}
.text-center {
  text-align: center;
}
.section_header {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}
.table_end {
  text-align: center;
}
.section_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}
.product_slider_top .slider-nav .slick-track .slide_thumb.slick-current {
  opacity: 1;
}
.toast {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  border-radius: 37.5px;
  background: #858585;
  padding: 20px;
  top: 4%;
  z-index: 5000;
  left: 38%;
}
.product_slider_item_content {
  font-weight: 400;
  margin-top: 30px;
  line-height: 28px;
}
.section_bg.opacity-1 {
  opacity: 0.4;
}
.product_slider .slick-list {
  overflow: hidden;
  width: 365px;
}
.system_slider .slick-track {
  display: flex;
  gap: 70px;
  min-width: 100%;
}
.slick-current .slide_thumb {
  opacity: 1 !important;
}
div.ss_item {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  flex-grow: 1;
  border-radius: 26px;
  overflow: hidden;
  width: 420px;
  height: 100%;
}
.ss_item_header {
  height: 112px;
  background-color: var(--green2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 10px;
}
.ss_item_header span {
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
}
.ss_item_img img {
  aspect-ratio: 16/10;
  object-fit: cover;
  object-position: center;
}
.ss_item_meta {
  background-color: var(--bgsection);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 29px;
  padding-bottom: 29px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 170px;
}
.ss_item_meta_desc {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-height: 70px;
}
.mobile-footer-link {
  font-family: var(--font);
  font-size: 13.89px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.ss_item_meta_action {
  display: flex;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}
.bg_green {
  background-color: var(--green2);
  color: #fff;
}
.cta_box {
  width: 100%;
  max-width: 972px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.cta_form {
  width: 100%;
}
form#security_options_form {
  width: 100%;
}
.sec_options_box {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 37.5px;
  background: #fff;
}
.sec_options_box button {
  flex-grow: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

button.btn_sec_option {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 18px 40px;
  width: 100%;
}
.div_sec_option {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 6px;
  border-right: solid 1px #bfc7c7;
  flex-grow: 1;
  cursor: pointer;
  box-sizing: border-box;
}
button#sec_opt_doors_windows {
  border-right: none;
}
button.btn_sec_option > span:first-child {
  font-size: 9.219px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(90, 98, 112, 1);
  margin-bottom: 6px;
}
button.btn_sec_option > span:last-child {
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-transform: capitalize;
  max-width: 165px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(90, 98, 112, 1);
}
.btn_security_option_active {
  border-radius: 30px;
  /*  background: #f3f5f4 !important; */
}
.sec_form_option {
  margin-top: 40px;
  border-radius: 26px;
  background: #f3f5f4;
  padding: 40px 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #5a6270;
  position: absolute;
  z-index: 1000;
}
.sec_form_option[data-button="sec_opt_residence"] {
  left: 24%;
}
.sec_form_option[data-button="sec_opt_doors_windows"] {
  left: 49%;
}
.sec_form_option p {
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0;
}
.sec_form_option p label {
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
button.btn_sec_option_finalize {
  margin: 5px 5px 5px 0;
  padding: 20px 30px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 116.667%;
  border-radius: 100px;
  background: #24725f;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6.799px;
  border: none;
  transition: all 0.3s ease;
}
button.btn_sec_option_finalize:hover {
  background-color: var(--bg);
}

/* Radio inputs */
.sec_form_option input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.sec_form_option input[type="radio"] + label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.sec_form_option input[type="radio"]:checked + label:before {
  background-color: var(--green);
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.sec_form_option input[type="radio"]:focus + label:before {
  outline: none;
  border-color: var(--green);
}
.sec_form_option input[type="radio"]:disabled + label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.sec_form_option input[type="radio"] + label:empty:before {
  margin-right: 0;
}
/* Radio inputs ends */

.sec_form_final {
  margin-top: 40px;
  border-radius: 26px;
  background: #f3f5f4;
  padding: 40px 40px;
  position: absolute;
  z-index: 9;
}
.sec_form_final .form_inputs {
  gap: 40px;
}
.sec_form_final .form_inputs input {
  color: #5a6270;
}
.sec_form_final .form_inputs ::placeholder {
  color: #5a6270;
  opacity: 0.8; /* Firefox */
}
.sec_form_final .form_inputs ::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #5a6270;
  opacity: 0.8;
}
.sec_form_final .form_inputs input {
  border-color: #5a6270;
}
.sec_form_final_bottom {
  margin-top: 40px;
  display: flex;
  gap: 77px;
}
.sec_form_final_bottom_left {
  font-family: "Open Sans";
  font-size: 11.438px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.148%;
  color: #5a6270;
}
.sec_form_final_bottom_left a {
  color: #858585;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
}
.sec_form_final_bottom_right {
  min-width: 270px;
}
.button_green_transparent {
  display: inline-flex;
  padding: 20px 39px;
  color: var(--green);
  text-align: center;
  font-size: 17.578px;
  font-style: normal;
  font-weight: 700;
  line-height: 119.467%;
  gap: 7px;
  border-radius: 100px;
  border: 2px solid var(--green);
  transition: all 0.3s ease-in-out;
}
.button_green_transparent:hover {
  background-color: var(--bg);
  border-color: var(--bg);
}

.container_box {
  background-color: var(--bgsection);
  padding: 50px 64px;
  border-radius: 26px;
}
.product_slider_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.product_slider_top .slider-nav {
  width: 450px;
}

.product_slider_item_body {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.product_slider_item_body {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 30px;
}
.product_slider_item_image img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 365px;
  object-fit: cover;
  object-position: center;
}
ul.nolist {
  list-style-type: none;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}
.slide_thumb_img img {
  width: 60px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
}
.slide_thumb_img {
  display: flex;
  width: 100px;
  background-color: #fff;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.product_container {
  padding-bottom: 50px;
}
.slide_thumb_txt {
  color: #fff;
  text-align: center;
  font-size: 13.672px;
  font-style: normal;
  font-weight: 400;
  line-height: 160.914%;
  margin-top: 10px;
}
.slick-slide {
  display: flex;
}
.slider-nav .slick-slider,
.slider-nav .slick-list {
  overflow: hidden;
}
.product_slider .slick-list .slick-track,
.title_slider .slick-list .slick-track {
  flex-direction: row;
  display: flex;
}
.product_slider_top .slider-nav .slick-track {
  display: flex;
  gap: 20px;
}
.product_slider_top .slider-nav .slick-track .slide_thumb {
  opacity: 0.4;
  cursor: pointer;
}
.product_slider_top .slider-nav .slick-track .slide_thumb.slick-current {
  opacity: 1;
}
.product_slider_item_content_action {
  padding-top: 10px;
}
.button_white_transparent {
  display: inline-flex;
  padding: 21px 40px;
  color: #fff;
  text-align: center;
  font-size: 17.578px;
  font-style: normal;
  font-weight: 700;
  line-height: 119.467%;
  gap: 8px;
  border-radius: 100px;
  border: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.button_white_transparent:hover {
  background-color: var(--bg);
}
.product_slider_top .slider-nav button.slick-arrow {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  user-select: none;
  -webkit-user-select: none;
}
.product_slider_top .slider-nav button.slick-arrow::before {
  display: none;
}
.product_slider_top .slider-nav {
  width: 450px;
  padding: 0 40px;
  position: relative;
}
.product_slider_top .slider-nav button.slick-arrow {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.slick-next {
  right: -25px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev {
  left: -25px;
}
.slick-prev,
.slick-next {
  margin-top: -15px;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.container_box + .container_box {
  margin-top: 80px;
}
table.table {
  width: 100%;
  border-collapse: collapse;
  /* table-layout: fixed; */
}
.table > :not(caption) > * > * {
  padding: 21px 0;
  border-bottom-width: 1px;
}
table.product_table th {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 200%;
  text-align: center;
}
table.product_table th span {
  color: #fff;
  font-size: 32.906px;
  font-style: normal;
  font-weight: 400;
  line-height: 109.402%;
}
table.product_table tbody tr {
  border-top: 1px solid #dddcdb;
}
table.product_table tbody td {
  color: #fff;
  font-size: 17.859px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.78%;
}
.table_legend {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 142.857%;
  margin-top: 26px;
}
.product_section .section_bg.opacity-1 {
  opacity: 0.6;
  background-position: left center;
}
.bg-effect-1 {
  position: relative;
}
.bg-effect-1 > * {
  position: relative;
}
.terms-link {
  text-decoration: none;
  cursor: pointer;
  color: #858585;
}
.quiz-action {
  color: var(--green);
  text-decoration: none;
  border: solid 2px var(--green);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.bg-effect-1::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 554px;
  background: rgba(83, 168, 127, 0.49);
  filter: blur(134.6999969482422px);
}
.logo_title span {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 260%;
}
.logo_title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}
ol.numol {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
ol.numol li {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}
ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding: 0;
}
.main_nav li {
  padding: 6px 0;
  margin: 0;
}
.current-item {
  counter-increment: my-awesome-counter;
  margin: 0.25rem;
  border-bottom: 1px solid rgba(81, 204, 71, 1);
  color: #fff;
}
ol.numol li::marker {
  position: relative;
  z-index: 9;
}
ol.numol li span {
  content: counter(my-awesome-counter);
  display: inline-flex;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  color: var(--bg);
  font-size: 1rem;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.gap-20 {
  gap: 20px;
}
.block_img.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.padding-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.free_quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.form_action {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}
.button.button-green {
  flex-wrap: nowrap;
  flex-direction: row;
  font-family: var(--font);
  padding: 21px 40px;
  border-radius: 100px;
  font-size: 17.016px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.416%;
  border: solid 2px transparent;
}
.button.button-green:hover {
  border-color: var(--bg);
  background-color: var(--bg);
}
.free_quote form {
  width: 100%;
}
.form_info {
  color: #fff;
  text-align: center;
  font-size: 11.438px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.148%;
  max-width: 675px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.form_inputs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
}
.form_inputs input {
  border: none;
  width: 100%;
  display: flex;
  height: 70px;
  font-family: "Open Sans";
  font-size: 23.813px;
  line-height: 117.585%;
  opacity: 0.7;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-end;
  outline: 0;
}
.form_inputs ::placeholder {
  color: #fff;
  opacity: 0.5; /* Firefox */
}
.form_inputs ::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #fff;
  opacity: 0.5;
}
table.product_table tbody td img {
  vertical-align: middle;
}
.testimonial_name {
  font-family: Arial;
  font-size: 21.31px;
  font-weight: 400;
  line-height: 28px;
  margin: 10px 0 6px 0;
  color: #fff;
}
.testimonial_date {
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(210, 210, 210, 1);
}
.testimonial_stars {
  display: flex;
  flex-wrap: nowrap;
  gap: 11px;
}
.testimonial_stars span {
  display: inline-flex;
  position: relative;
}
.testimonial_stars span::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzFfMTE1MykiPg0KICAgIDxwYXRoIGQ9Ik0xMS4xMjk0IDEuNDQ4NjZDMTEuNDI3NiAwLjUyNDkyNSAxMi43MzQ1IDAuNTI0OTI4IDEzLjAzMjcgMS40NDg2NkwxNS4xMDIyIDcuODU5NzRDMTUuMjM1OCA4LjI3MzQ1IDE1LjYyMTMgOC41NTM0OCAxNi4wNTYgOC41NTI1M0wyMi43OTY2IDguNTM3ODNDMjMuNzY3OCA4LjUzNTcgMjQuMTcxNiA5Ljc3OTYgMjMuMzg0NCAxMC4zNDg0TDE3LjkyNDIgMTQuMjkzNkMxNy41NzE1IDE0LjU0ODQgMTcuNDI0MSAxNS4wMDIxIDE3LjU1OTUgMTUuNDE1NUwxOS42NTYzIDIxLjgxNDlDMTkuOTU4NiAyMi43Mzc1IDE4LjkwMTIgMjMuNTA2MiAxOC4xMTY4IDIyLjkzNDJMMTIuNjcwMiAxOC45NjI2QzEyLjMxOTEgMTguNzA2NiAxMS44NDI5IDE4LjcwNjYgMTEuNDkxOCAxOC45NjI2TDYuMDQ1MjcgMjIuOTM0MkM1LjI2MDgxIDIzLjUwNjIgNC4yMDM1IDIyLjczNzUgNC41MDU3OCAyMS44MTQ5TDYuNjAyNTEgMTUuNDE1NUM2LjczNzk4IDE1LjAwMjEgNi41OTA1NSAxNC41NDg0IDYuMjM3ODcgMTQuMjkzNkwwLjc3NzYwOSAxMC4zNDg0Qy0wLjAwOTU5MzAxIDkuNzc5NiAwLjM5NDI2NSA4LjUzNTcgMS4zNjU0NCA4LjUzNzgzTDguMTA2MDEgOC41NTI1M0M4LjU0MDc0IDguNTUzNDggOC45MjYyOSA4LjI3MzQ1IDkuMDU5ODQgNy44NTk3NEwxMS4xMjk0IDEuNDQ4NjZaIiBmaWxsPSJ3aGl0ZSIvPg0KICA8L2c+DQogIDxkZWZzPg0KICAgIDxjbGlwUGF0aCBpZD0iY2xpcDBfMV8xMTUzIj4NCiAgICAgIDxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyMyIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC41KSIvPg0KICAgIDwvY2xpcFBhdGg+DQogIDwvZGVmcz4NCjwvc3ZnPg==");
}
.testimonial_slider .slick-track {
  display: flex;
  gap: 24px;
  justify-content: stretch;
  align-items: stretch;
}
div.testimonial_slider .testimonial_slider_item {
  border-radius: 26px;
  background: var(--bgsection);
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: inherit;
}
.testimonial_title {
  font-family: Arial;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-top: 40px;
}
.testimonial_content {
  color: #d2d2d2;
  font-family: Arial;
  font-size: 19.38px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 10px;
}
.testimonial_action {
  margin-top: 10px;
}
.testimonial_action a {
  color: #fff;
  font-size: 17.016px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.293%;
  text-decoration-line: underline;
  transition: all 0.3s ease;
}
.testimonial_action a:hover {
  margin-left: 3px;
}
footer {
  background-color: #23252a;
  padding-top: 42px;
  padding-bottom: 90px;
  color: #fff;
}
.footer_top {
  display: flex;
  column-gap: 110px;
  align-items: center;
}
ul.footer_nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 37px;
}
ul.footer_nav a,
.policy-link {
  color: #fff;
  font-size: 13.891px;
  font-style: normal;
  font-weight: 400;
  line-height: 172.778%;
  transition: all 0.3s ease;
  cursor: pointer;
}
ul.footer_nav a:hover {
  opacity: 0.6;
}
.footer_copy {
  margin-top: 100px;
  color: #909999;
  font-size: 10.656px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.762%;
}

/* steps */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

div#form_start_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

div.form_start_box {
  min-height: 766px;
  width: 1072px;
  display: flex;
  flex-direction: column;
  max-width: 96vw;
  border-radius: 26px;
  background-color: #23252a;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.form_start_header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 113px;
  background-color: #4f5155;
  padding: 24px 40px;
}
span.form_start_header_title {
  margin: auto;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 270.833%;
}
.form_start_content {
  padding: 40px 40px;
  position: relative;
}
.link {
  text-decoration: none;
  cursor: pointer;
  color: #858585;
  font-weight: 700;
}
.link-finalize {
  text-decoration: none;
  cursor: pointer;
  color: #848484;
  font-weight: 700;
}
.form_start_content_steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.step > span:last-child {
  position: absolute;
}
.step_line {
  width: 262px;
  height: 1px;
  background-color: #d2d2d2;
}
.step_line_success {
  background-color: var(--green);
}
.step > span:last-child {
  position: absolute;
  transform: translateY(200%);
}
.step > span:first-child {
  display: flex;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: solid 1px #d2d2d2;
  justify-content: center;
  align-items: center;
}
.step.step2.success {
  border-color: var(--green);
  color: var(--green);
}
div#fs_step_3 .step.step3 > span:first-child {
  border-color: var(--green);
  color: var(--green);
}
.step.step1 > span:first-child {
  border-color: var(--green);
  color: var(--green);
}
.step.step2 > span:first-child {
  border-color: var(--green);
  color: var(--green);
}

div#fs_step_2 .step.step1 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_3 .step.step1 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_3 .step.step2 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_4 .step.step1 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_4 .step.step2 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_4 .step.step3 > span:first-child {
  border-color: transparent;
  color: var(--green);
}
div#fs_step_2 .step_line {
  background-color: var(--green);
}
div#fs_step_3 .step_line {
  background-color: var(--green);
}
div#fs_step_4 .step_line {
  background-color: var(--green);
}
.sys_products {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.sys_products div.ss_item {
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 30%;
}
.sys_products div.ss_item .ss_item_header {
  padding: 20px 20px;
}
.sys_products div.ss_item .ss_item_meta_action svg circle {
  stroke: #ababab;
}
.sys_products div.ss_item .ss_item_meta_action svg rect {
  fill: #ababab;
}
.sys_products div.ss_item.selected .ss_item_meta_action svg circle {
  stroke: var(--green);
}
.sys_products div.ss_item.selected .ss_item_meta_action svg rect {
  fill: var(--green);
}
.sys_products div.ss_item input[type="radio"] {
  position: absolute;
  height: 0;
  visibility: hidden;
}
.sys_products div.ss_item label {
  cursor: pointer;
}
body.quiz_form_active {
  position: absolute;
  width: 100%;
  overflow: hidden;
}
div#fs_step_2.form_start_box {
  opacity: 0;
  position: absolute;
}
div#fs_step_2.form_start_box.active {
  opacity: 1;
  position: relative;
  z-index: 1;
}
div#fs_step_1.form_start_box.hide {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
div#fs_step_3.form_start_box {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
div#fs_step_3.form_start_box.active {
  opacity: 1;
  position: relative;
  z-index: 1;
}
div#fs_step_4.form_start_box {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
div#fs_step_4.form_start_box.active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.product_type_select_box_header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  border-radius: 37.5px;
  background: #4f5155;
  padding: 18px 40px;
  position: relative;
  z-index: 9;
}
.product_type_line {
  width: 1px;
  background-color: #bfc7c7;
  align-self: stretch;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 50%;
  height: 50%;
}
.product_type_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.product_type_title > span:first-child {
  color: #fff;
  font-size: 9.219px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.169%;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.product_type_title > span:last-child {
  color: #fff;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 122.581%;
  text-transform: capitalize;
}
.product_type_select_box_content {
  background-color: #303133;
  margin-top: -35px;
  padding-top: 75px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.form_start_content_footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
a.step_back {
  display: flex;
  align-items: center;
  gap: 8px;
}
a.step_back svg {
  opacity: 0.5;
  width: 20px;
  fill: #fff;
}
a.step_next {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
}
a.step_next svg {
  opacity: 0.5;
  transform: rotate(180deg);
  width: 20px;
  fill: #fff;
}

/* Radio inputs 2 */
.product_type_select_radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.product_type_select_radio input[type="radio"] + label:before {
  content: "";
  background: transparent;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.product_type_select_radio input[type="radio"]:checked + label:before {
  background-color: var(--green);
  box-shadow: inset 0 0 0 4px #303133;
}
.product_type_select_radio input[type="radio"]:focus + label:before {
  outline: none;
  border-color: var(--green);
}
.product_type_select_radio input[type="radio"]:disabled + label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.product_type_select_radio input[type="radio"] + label:empty:before {
  margin-right: 0;
}
/* Radio inputs ends */

.product_type_select_radio {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.product_type_select_radio p {
  margin: 0;
  color: #fff;
  display: flex;
  align-items: center;
}
.product_type_select_line {
  width: 1px;
  background-color: #555a5a;
  position: absolute;
  height: 50%;
  left: 50%;
}
.product_type_select_nums_row {
  display: flex;
  color: #fff;
}
.product_type_select_nums_row div {
  background-color: transparent;
  border: none;
  color: #fff;
  width: 30px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  -moz-appearance: textfield; /* Firefox */
}
/* Chrome, Safari, Edge, Opera */
.product_type_select_nums_row input::-webkit-outer-spin-button,
.product_type_select_nums_row input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product_type_select_nums_row span.label {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: auto;
  user-select: none;
}
.product_type_select_nums {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.user-select-none{
  user-select: none;
}
span.minus {
  cursor: pointer;
}
span.plus {
  cursor: pointer;
}

.middle-slider-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form_start_quote {
  border-radius: 26px;
  background: #4f5155;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: #fff;
}
.form_start_quote h4 {
  margin: 0;
  line-height: normal;
}
.form_start_quote p {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
}
.form_start_quote p.q_bottom {
  color: #fff;
  text-align: center;
  font-size: 11.438px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.148%;
}
.form_start_quote p.q_bottom a {
  color: #858585;
  font-style: normal;
  text-decoration-line: underline;
}
.form_start_quote_inputs {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 400px;
}
input.reg::placeholder {
  color: #5a6270;
}
.form_start_quote_input input {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  background: transparent;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: solid 1px #ccc;
  padding-bottom: 13px;
  padding-top: 20px;
}
.form_start_quote_input input:active {
  background: transparent !important;
}
.form_start_quote_input input:focus {
  background: transparent !important;
}
.form_start_quote_input input:-webkit-autofill,
.form_start_quote_input input:-webkit-autofill:hover,
.form_start_quote_input input:-webkit-autofill:focus,
.form_start_quote_input input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.form_start_quote_input input:focus-visible {
  border: none;
  border-bottom: solid 1px #ccc;
  outline: none;
}
.form_start_quote_input ::placeholder {
  color: #fff;
  opacity: 0.5; /* Firefox */
}
.form_start_quote_input ::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #fff;
  opacity: 0.5;
}

.form_start_footer {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.form_start_footer:empty {
  display: none;
}
button.btn_quote_form {
  border-radius: 40px;
  background: var(--green);
  display: inline-flex;
  padding: 20px 40px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  transition: all 0.3s ease;
}
.confirm-button {
  align-self: center;
  border-radius: 10px;
  background: var(--green);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border: none;
  transition: all 0.3s ease;
  width: 225px;
  height: 47px;
}
button.btn_quote_form:hover {
  background: var(--bg2);
}

.form_quote_final_wrapper{
  padding: 0 16px;
  box-sizing: border-box;
}

.form_quote_final {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 26px;
  background: #4f5155;
  gap: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.final_txt {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Radio inputs 3 */
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + label:before {
  background-color: var(--green);
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + label:before {
  outline: none;
  border-color: var(--green);
}
.radio input[type="radio"]:disabled + label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + label:empty:before {
  margin-right: 0;
}
/* Radio inputs ends */

/* privacy */

.privacy_section {
  background-color: #27292d;
  color: #fff;
}
.container.container-small {
  max-width: 730px;
}
.btn-green-transparent {
  font-family: var(--font2);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 155.556%;
  color: var(--green);
  display: inline-flex;
  padding: 14px 24px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  border: 2px solid var(--green);
  transition: all 0.3s ease-in-out;
}
.btn-green-transparent:hover {
  /* background-color: var(--bg2); */
  opacity: 0.6;
}
article p {
  line-height: 1.7;
  color: #d2d2d2;
}
.article_meta {
  margin-bottom: 2rem;
  color: #d2d2d2;
}
.article_header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.article_action {
  margin-top: 2rem;
}
.testimonial_slider_item {
  border-radius: 26px;
  background: var(--bgsection);
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
}

/* Global ends
--------------------------------------------------------------------------------------------------------------------------------------- */

@media screen and (min-width: 1025px) {
  .mob {
    display: none !important;
  }
} /* /min-width:768px 
--------------------------------------------------------------------------------------------------------------------------------------- */

@media screen and (max-width: 1024px) {
  * {
    box-sizing: border-box;
  }
  table {
    table-layout: fixed;
    width: 100%;
  }
  img {
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
  body {
    margin: 0px;
    padding: 0px;
  }
  iframe {
    max-width: 100% !important;
  }
  object {
    max-width: 100% !important;
  }
  .pc {
    display: none !important;
  }

  .header_wrapper > nav.navigation {
    display: none;
  }
  .header_wrapper > .action {
    display: none;
  }
  .mob_nav_wrapper {
    height: -webkit-fill-available;
    z-index: 9;
    right: -120vw;
    position: fixed;
    overflow-y: auto;
    width: 100%;
    top: 75px;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 110px;
  }
  .mob_nav_wrapper.active {
    right: 0px;
  }
  .mob_nav_wrapper nav.navigation {
    position: relative;
  }
  .mob_nav_wrapper ul.main_nav {
    flex-direction: column;
    gap: 16px;
  }
  .mob_nav_wrapper ul.main_nav a {
    padding: 30px 30px;
    border-radius: 18px;
    border: 1px solid #c0c0c0;
    background: #24252b;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    min-width: 343px;
    max-width: 90%;
    display: flex;
    justify-content: center;
  }
  .mob_nav_footer {
    margin-top: auto;
    padding: 40px 16px 16px 16px;
    color: #fff;
    background: rgba(36, 37, 43, 0.8);
    backdrop-filter: blur(24px);
    width: 100%;
  }
  .mob_nav_footer ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    flex-direction: column;
    gap: 12px;
  }
  .mob_nav_footer ul a {
    color: #fff;
    font-size: 13.891px;
    font-style: normal;
    font-weight: 600;
    line-height: 172.778%;
  }
  .mob_nav_footer_copy {
    margin-top: 100px;
  }
  .mob_nav_footer_copy p {
    color: #909999;
    font-size: 10.656px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.762%;
  }
  .mob_nav_wrapper .action {
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
  }
  .mob_nav_wrapper .action a.btn.btn-action,
  .mob_nav_wrapper .action span.btn.btn-action {
    padding: 30px 30px;
    border-radius: 18px;
    border: 1px solid var(--green);
    background: transparent;
    color: var(--green);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    min-width: 343px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  a.mob_btn_open svg.icon_close {
    display: none;
  }
  a.mob_btn_open.active svg.icon_close {
    display: inline-block;
  }
  a.mob_btn_open.active svg.icon_open {
    display: none;
  }
  .product_slider_top .slider-nav {
    padding: 0 30px;
  }
  .product_slider {
    overflow: hidden;
    width: 365px;
    position: absolute;
    top: 140px;
    left: 21%;
  }
  .system_slider .slick-track {
    display: flex;
    gap: 20px;
    min-width: 100%;
  }

  .sys_products {
    flex-wrap: wrap;
    display: flex;
    gap: 16px;
  }
  .sys_products div.ss_item {
    flex-direction: row;
    flex-basis: 100%;
  }
  .sys_products div.ss_item > label {
    display: flex;
  }
  .sys_products div.ss_item .ss_item_header {
    display: none;
  }
  .ss_item_img {
    position: relative;
  }
  .ss_item_img .mob {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    flex-direction: column;
    justify-content: center;
    padding: 12px 12px;
    background-color: rgba(7, 7, 7, 0.53);
    color: #fff;
    gap: 8px;
  }
  span.mob_ss_item_header {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .mob_ss_item_meta_desc {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ss_item_meta {
    background-color: var(--bgsection);
  }
  div#form_start_wrapper {
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    align-items: flex-start;
    padding-top: 50px;
  }
  div.form_start_box {
    max-width: calc(100vw - 2rem);
  }
  .ss_item_img img {
    aspect-ratio: 16/7;
    object-fit: cover;
    object-position: center;
  }
} /* /min-width:1024px 
--------------------------------------------------------------------------------------------------------------------------------------- */

@media screen and (min-width: 768px) {
  .mob-only {
    display: none !important;
  }
} /* /min-width: 768px 
--------------------------------------------------------------------------------------------------------------------------------------- */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  button.btn_sec_option > span:last-child {
    max-width: 110px;
  }
  table.table.product_table thead tr > th:first-child {
    width: 75%;
    padding-right: 15px;
  }
  table.table.product_table tbody tr > td:first-child {
    /* width: 75%; */
    padding-right: 15px;
  }
  section.header_section .col-50 {
    width: 70%;
  }
} /* /(min-width: 768px) and (max-width: 1024px)
--------------------------------------------------------------------------------------------------------------------------------------- */

@media screen and (max-width: 767px) {
  .product_slider_top {
    flex-direction: column;
  }
  .product_slider_top .slider-nav {
    width: 6%;
    margin-left: 25px;
    margin-top: 40px;
  }
  .col-50 {
    width: 100%;
  }
  .row {
    flex-wrap: wrap;
  }
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .product_slider_item_body {
    flex-wrap: wrap;
  }
  .product_slider_item_image img {
    width: 200px;
  }
  .product_slider_top .slider-nav .slick-track {
    display: none;
  }
  .product_slider_top .slider-nav button.slick-arrow {
    position: relative;
    left: auto;
    right: auto;
    width: 30px;
    height: 30px;
  }
  .product_slider_top .slider-nav button.slick-arrow svg {
    width: 20px;
    height: 20px;
  }
  .product_slider_top .slider-nav {
    display: flex;
    padding: 0;
    gap: 8px;
  }
  .logo img {
    width: 51px;
    height: 50px;
  }
  .logo a {
    display: flex;
  }
  .header_wrapper {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 18px;
  }
  .system_slider .slick-track {
    display: flex;
    gap: 30px;
    min-width: 100%;
    justify-content: stretch;
    align-items: stretch;
  }
  .system_slider .slick-track::before {
    display: none;
  }
  .system_slider .slick-track::after {
    display: none;
  }
  .section {
    padding: 40px 0;
    overflow: hidden;
  }
  .ss_item_header span {
    font-size: 18px;
  }
  .ss_item_header {
    height: 77px;
  }
  .system_slider div.ss_item {
    display: flex;
    flex-basis: unset;
    flex-grow: 1;
    border-radius: 26px;
    overflow: hidden;
    height: auto;
    flex-direction: column;
    width: 290px;
  }
  .ss_item_meta_desc {
    min-height: 60px;
    overflow: hidden;
    display: none;
    /* display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
  }
  .system_slider .ss_item_meta_desc {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 0;
    font-size: 16px;
  }
  .system_slider .ss_item_meta {
    padding-top: 20px;
  }
  .system_slider {
    padding-left: 40px;
  }
  .product_slider_item_image {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 15px;
  }
  .container.container_box {
    max-width: calc(100% - 2rem);
    height: 100%;
  }
  .container_box + .container_box {
    margin-top: 40px;
  }
  table.product_table th span {
    font-size: 26px;
  }
  table.table.product_table > thead > tr > th:first-child {
    width: 65%;
  }
  table.product_table tbody td {
    font-size: 12px;
  }
  table.product_table tbody td img {
    width: 24px;
    vertical-align: middle;
  }
  table.table.product_table th img {
    width: 43px;
  }
  table.product_table th {
    font-size: 9px;
  }
  .table_legend {
    font-size: 12px;
  }
  ol.numol li {
    font-size: 16px;
  }
  .block_img img {
    width: 235px;
    max-width: 100%;
  }
  .form_inputs {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
    width: 100%;
  }
  .form_inputs input {
    height: 35px;
    font-size: 17px;
    color: #5a6270;
    border-color: #5a6270;
    font-style: normal;
    font-weight: 400;
  }
  .testimonial_slider .slick-track::after {
    display: none;
  }
  .testimonial_slider .slick-track::before {
    display: none;
  }
  .testimonial_slider .slick-track {
    gap: 1rem;
  }
  div.testimonial_slider .testimonial_slider_item {
    padding: 27px 27px;
  }
  .footer_top {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer_logo img {
    width: 51px;
  }
  ul.footer_nav {
    flex-direction: column;
    gap: 16px;
  }
  ul.footer_nav li {
    margin: 0px;
  }
  ul.footer_nav a {
    line-height: 1;
  }
  .footer_links {
    margin-top: 20px;
  }
  .mob.mob_nav_wrapper {
    padding-top: 70px;
  }
  .ss_item_meta {
    padding: 0 15px;
  }
  .ss_item_img img {
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
  .form_start_content {
    padding: 1rem 1rem;
  }
  .form_start_header {
    padding: 15px 15px;
    height: 56px;
  }
  span.form_start_header_title {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .step > span:last-child {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .product_type_select_box_content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 50px;
    margin: 0;
    padding: 30px 15px;
  }
  .product_type_select_line {
    position: relative;
    width: 100%;
    height: 1px;
    left: 0;
  }
  .product_type_select_nums {
    width: 100%;
  }
  .product_type_select_box_header.pc_version .product_type_line {
    display: none;
  }
  .product_type_select_box_header.pc_version
    .product_type_line
    + .product_type_title {
    display: none;
  }
  .cta_form {
    display: none;
  }
  .cta_form_mob {
    padding: 1rem 1rem;
    border-radius: 26px;
    background: #f3f5f4;
    width: 100%;
  }
  .sec_options_box_mob_title {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 24px;
  }
  .sec_options_box_mob_title > span:first-child {
    color: #5a6270;
    font-size: 9.531px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.902%;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .sec_options_box_mob_title > span:last-child {
    color: #5a6270;
    font-size: 15.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 122.581%;
    text-transform: capitalize;
  }
  .sec_options_box_fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #5a6270;
  }
  .sec_options_box_fields p {
    margin: 0;
    text-align: left;
  }
  .sec_options_box_footer {
    margin-top: 43px;
    display: flex;
    position: relative;
    justify-content: center;
    height: 30px;
    align-items: center;
  }
  .sec_options_box_1 a.step_back {
    display: none;
  }
  .cta_form_mob a.step_next svg {
    fill: var(--green);
  }
  .cta_form_mob a.step_next {
    position: absolute;
    right: 0;
  }
  .mob_process {
    display: flex;
    gap: 10px;
  }
  .mob_process span {
    width: 8px;
    height: 8px;
    background-color: var(--green);
    border-radius: 50%;
  }
  .sec_options_box_1 span.dot1 {
    width: 20px;
    border-radius: 25px;
  }
  .sec_options_box_2 span.dot2 {
    width: 20px;
    border-radius: 25px;
  }
  .sec_options_box_3 span.dot3 {
    width: 20px;
    border-radius: 25px;
  }
  form#security_options_form_mob > div {
    transition: all 0.3s ease-in-out;
  }
  form#security_options_form_mob > .closed {
    opacity: 0;
    position: absolute;
    z-index: -9;
  }
  .cta_form_mob a.step_back {
    position: absolute;
    left: 0;
    font-size: 0.9rem;
    color: var(--green);
  }
  .cta_form_mob a.step_back svg {
    fill: var(--green);
  }
  form#security_options_form_mob .form_inputs input {
    color: #5a6270;
  }
  form#security_options_form_mob .form_inputs ::placeholder {
    color: #5a6270;
    opacity: 0.8; /* Firefox */
  }
  form#security_options_form_mob .form_inputs ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #5a6270;
    opacity: 0.8;
  }
  form#security_options_form_mob .form_inputs input {
    color: #5a6270;
    border-color: #5a6270;
  }
  form#security_options_form_mob .form_inputs {
    padding-top: 20px;
  }
  .sec_options_box_mob_txt {
    color: #5a6270;
    font-size: 10px;
    margin: 30px 0;
  }
  .sec_options_box_4 a.step_next {
    display: none;
  }
  .article_header svg {
    width: 60px;
    height: 60px;
  }
}
div.step_next,
div.step_back {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
}

div.step_next svg {
  transform: rotate(180deg);
  width: 25px;
  fill: #fff;
}

div.step_back svg {
  width: 20px;
  fill: #fff;
}

@media screen and (max-width: 767px) {
  .cta_form_mob div.step_next svg {
    fill: var(--green);
  }
  .cta_form_mob div.step_next_disabled svg {
    fill: rgba(210, 210, 210, 1);
  }
  .cta_form_mob div.step_back svg {
    fill: #b1adad;
  }
  .cta_form_mob div.step_next {
    position: absolute;
    right: 0;
  }
  .cta_form_mob div.step_back {
    position: absolute;
    left: 0;
    font-size: 0.9rem;
    color: var(--green);
  }
  .ss_item_header span,
  h4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .mob_nav_wrapper ul.main_nav li {
    border-radius: 18px;
    border: 1px solid silver;
    background: #24252b;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding: 30px;
    font-size: 24px;
    line-height: 1.1;
    min-width: 343px;
    max-width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .title_slider {
    margin-top: 240px;
    text-align: left;
  }
  .product_slider_item_content {
    max-width: 340px;
  }
  .testimonial_slider_item {
    width: 280px;
    padding: 27px;
    height: 360px;
  }
  .security_step_back,
  .security_step_next {
    font-size: 0.9rem;
    color: rgba(210, 210, 210, 1);
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .security_step_back svg {
    opacity: 0.5;
    width: 20px;
    fill: rgba(210, 210, 210, 1);
  }
  .security_step_next svg {
    width: 25px;
    fill: #fff;
  }
  .security_step_next svg {
    transform: rotate(180deg);
  }
  footer {
    padding-bottom: 40px;
  }
  .testimonial_action {
    margin-top: 0px;
  }
  .testimonial_name {
    font-family: Helvetica Neue;
    font-size: 15.75px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px 0 0 0;
  }
  .testimonial_date {
    font-family: Helvetica Neue;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(210, 210, 210, 1);
  }
  .testimonial_title {
    margin-top: 30px;
    font-family: Helvetica Neue;
    font-size: 15.25px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  }
  .testimonial_content {
    font-family: Helvetica Neue;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  .testimonial_action span {
    font-family: Helvetica Neue;
    font-size: 11.06px;
    font-weight: 700;
    line-height: 15px;
    text-align: left;
  }
  .title_slider .slick-list {
    height: auto !important;
  }

  .product_slider_item_content {
    margin-top: 0px;
  }
  .product_container {
    padding: 40px 16px;
  }
  .product_slider_title {
    font-size: 26px;
    font-weight: 400;
  }
  .product_slider_desc {
    font-size: 15.38px;
    font-weight: 400;
  }
  .height_auto {
    height: auto;
  }
  .form_start_footer {
    padding: 40px 0 20px 0;
  }
  .form_start_quote {
    padding: 40px 16px;
    align-items: start;
  }
  .form_start_quote p {
    text-align: left !important;
  }
  .form_action {
    align-self: flex-start;
    margin-top: 16px;
  }
  .form_start_quote h4 {
    font-size: 26px;
    font-weight: 400;
  }
  .action_mobile {
    margin-top: 30px;
    width: 100%;
  }
  .header_wrapper {
    min-height: 74px;
  }
  .toast {
    top: 4%;
    left: 16%;
  }
  .form_start_quote_inputs {
    width: 100%;
  }
  .ss_item_meta_action {
    margin-top: 0px;
  }
}
